<template>
    <div class="card h-100 border-2 border-secondary">
        <img :src="product[selectedIndex].image" class="card-img-top" alt="imagen de copa menstrual">
        <div class="card-body d-flex flex-column justify-content-around">
            <div class="input-group mb-3">
                <select v-model="selectedIndex" class="form-select">
                    <option v-for="(option, index) of product" :key="index" :value="index">
                        {{ option.name }}
                    </option>
                </select>
            </div>
<!--            <h5 class="card-title text-center">{{ product[selectedIndex].name }}</h5>-->

            <a target="_blank" :href="product[selectedIndex].url" class="btn btn-primary d-block"
               style="background:#cf0770; border-color:#cf0770">Comprar
                ahora</a>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProductCard",
    props: ['product'],
    data: function () {
        return {
            selectedIndex: 0
        }
    }
}
</script>

<style scoped>

</style>
