<template>
    <div>
        <div class="container pt-5" v-if="firstPage">
            <div class="row">
                <div class="col-12">
                    <h3 class="text-center mb-4">
                        Contesta 9 sencillas preguntas para ayudarte a elegir tu copita ideal para ti
                    </h3>

                    <button class="btn btn-primary d-block m-auto mb-3"
                            style="background:#cf0770; border-color:#cf0770" @click="showSurveyFn">
                        Empezar
                    </button>
                </div>
            </div>
        </div>


        <survey v-if="!firstPage && showSurvey" :survey="survey"/>
        <div v-if="showSurvey">
            <img style="display: block; margin:auto; width: 250px;"
                 :src="require('./assets/images/' + currentImage +'.jpeg')" alt="">
        </div>
        <div class="container pt-5" v-if="!showSurvey">
            <div class="row">
                <div class="col-12">
                    <h3 class="text-center mb-4">Tu mejor opción es: {{ selectedResult.title }}</h3>
                </div>
                <div v-for="(option, index) of selectedResult.options" :key="index" class="col-12 col-md-4 mb-3">
                    <product-card :product="option"></product-card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as Survey from "survey-vue";
import "survey-vue/modern.css";
import ProductCard from "@/components/ProductCard";

Survey.StylesManager.applyTheme("modern");

export default {
    name: 'App',
    components: {ProductCard},
    data() {
        const questions = {
            pages: [
                {
                    name: "page1",
                    elements: [
                        {
                            type: "radiogroup",
                            name: "question1",
                            title: "¿Cuántos años tienes? Muchas chicas prefieren tallas más pequeñas. Los músculos del suelo pélvico también pueden debilitarse con la edad, lo que puede afectar qué copa le conviene. La edad no es el factor más importante a la hora de elegir una taza. pero es un indicador",
                            isRequired: true,
                            colCount: 4,
                            requiredErrorText: 'Seleccione una opción',
                            choices: [
                                {value: 1, text: "Menos de 18"},
                                {value: 2, text: "18-25"},
                                {value: 3, text: "26-35"},
                                {value: 4, text: "Mayor de 35"},
                            ]
                        },
                    ]
                },
                {
                    name: 'page2',
                    elements: [
                        {
                            type: "radiogroup",
                            name: "question2",
                            title: "¿Qué usas habitualmente durante la menstruación?",
                            isRequired: true,
                            requiredErrorText: 'Seleccione una opción',
                            colCount: 4,
                            choices: [
                                "Toalla sanitaria",
                                "Tampones",
                                "Bragas menstruales",
                                "Copa menstrual",
                            ]
                        }
                    ]
                },
                {
                    name: 'page3',
                    elements: [
                        {
                            type: "boolean",
                            name: "question3",
                            title: "¿Haz usado alguna vez una copa menstrual?",
                            defaultValue: false,
                            isRequired: true,
                            labelTrue: 'Si',
                            labelFalse: 'No'
                        }
                    ]
                },
                {
                    name: 'page4',
                    elements: [
                        {
                            type: "radiogroup",
                            name: "question4",
                            title: "¿Qué tan fuerte es tu periodo?",
                            isRequired: true,
                            requiredErrorText: 'Seleccione una opción',
                            colCount: 4,
                            choices: [
                                "Muy fuerte",
                                "Fuerte",
                                "Normal",
                                "Débil",
                            ]
                        }
                    ]
                },
                {
                    name: 'page5',
                    elements: [
                        {
                            type: "checkbox",
                            name: "question5",
                            title: "Acerca de tu cérvix: Selecciona según tus mejores opciones y elige la que mejor te describa. Puedes marcar más de una respuesta",
                            isRequired: false,
                            colCount: 2,
                            choices: [
                                "Tengo un cuello uterino colocado arriba; es difícil alcanzarlo (es decir, unos 55 mm o más)",
                                "Es tan común alto que puedo alcanzarlo fácilmente con mi dedo índice (entre 45-55 mm)",
                                "Se coloca bajo, puedo alcanzarlo muy fácilmente (hasta aproximadamente 44 mm)",
                                "Nunca lo he investigado, pero regularmente estoy usando los tampones grandes",
                                'Nunca lo he investigado y ni siquiera sé dónde está',
                                'Nunca lo he investigado, pero uso mini tampones sin ningún problema.',
                                'Me dijeron que tenía el útero inclinado',
                                'Frecuentamente tengo calambres durante la menstruación.'
                            ]
                        }
                    ]
                },
                {
                    name: 'page6',
                    elements: [
                        {
                            type: "radiogroup",
                            name: "question6",
                            title: "¿Qué tan activa eres?",
                            isRequired: true,
                            colCount: 4,
                            requiredErrorText: 'Seleccione una opción',
                            choices: [
                                {value: 1, text: "Soy muy activa, varias veces a la semana en el gimnasio."},
                                {value: 2, text: "Soy activa: frecuentamente corro, camino, practico yoga, otros"},
                                {value: 3, text: "Tengo un movimiento normal. Nada de acción"},
                                {value: 4, text: "No estoy activa. Solo corro cuando algo me persigue."},
                            ]
                        }
                    ]
                },
                {
                    name: 'page7',
                    elements: [
                        {
                            type: "boolean",
                            name: "question7",
                            title: "¿Tienes hijos?",
                            defaultValue: false,
                            isRequired: true,
                            labelTrue: 'Si',
                            labelFalse: 'No'
                        }
                    ]
                },
                {
                    name: 'page8',
                    elements: [
                        {
                            type: "radiogroup",
                            name: "question8",
                            title: "¿Tienes incontinencia?",
                            isRequired: true,
                            colCount: 3,
                            requiredErrorText: 'Seleccione una opción',
                            choices: [
                                "Tengo incontinencia fuerte",
                                "Solo tengo incontinencia débil: al saltar, estornudar",
                                "No sufro de incontinencia",
                            ]
                        }
                    ]
                },
                {
                    name: 'page9',
                    elements: [
                        {
                            type: "checkbox",
                            name: "question9",
                            title: "¿Cuál es su experiencia con las copas? Elija una opción que se aplique normalmente, no una que haya sucedido una vez. Elija todo lo que corresponda y ayúdenos a elegir una copa que se adapte a sus necesidades.",
                            isRequired: false,
                            colCount: 2,
                            choices: [
                                "Esto no se aplica a mí. Todavía no he probado la copa",
                                "Mi copa me parece demasiado larga para mí a pesar de que corté el tallo",
                                {value: 1, text: "La copa no se abre en mi"},
                                "La copa presiona contra mi vejiga",
                                "Insertar la copa es doloroso / incómodo",
                                "La copa está demasiado alto, cuando quiero quitarla",
                                "La copa se cae cuando la tengo",
                                "Estoy feliz con mi copa",
                                {value: 2, text: "Tengo fugas"},
                            ]
                        }
                    ]
                },
            ]
        };

        const survey = new Survey.Model(questions);

        survey.pageNextText = 'Siguiente';
        survey.pagePrevText = 'Anterior';
        //survey. = 'Por favor responda la pregunta';

        return {
            survey,
            showSurvey: true,
            selectedResult: null,
            currentImage: 0,
            firstPage: true,
        }
    },
    methods: {
        showSurveyFn() {
            console.log(this.currentImage);
            this.currentImage++;

            console.log(this.currentImage);
            this.firstPage = false;
        }
    },
    mounted() {
        //const question = this.survey.getQuestionByName('question3');
        //question.defaultValue = false;

        this.survey.onCurrentPageChanged.add((sender, options) => {
            console.log(options);
            if (options.isNextPage) {
                this.currentImage++;
            } else if (options.isPrevPage) {
                if (this.currentImage > 0) {
                    this.currentImage--;
                }
            }
        });

        this.survey.onComplete.add((sender) => {
            console.log(sender.data);
            const q1 = sender.data.question1;
            const q6 = sender.data.question6;
            const q7 = sender.data.question7;
            const q9 = sender.data.question9;

            const options = [
                {
                    title: 'Soft pequeña',
                    options: [
                        [
                            {
                                url: 'https://www.yuukicup.mx/comprar-copa-menstrual/menstrual-cup-soft',
                                image: 'https://www.yuukicup.mx/images/products/10/Yuuki-48%20-%20copia.jpg',
                                name: 'Copa menstrual SOFT'
                            },
                            {
                                url: 'https://www.yuukicup.mx/comprar-copa-menstrual/menstrual-cup-soft-&-bolsa',
                                image: 'https://www.yuukicup.mx/images/products/11/Yuuki-44%20-%20copia.jpg',
                                name: 'Copa menstrual SOFT ECONOMIC + bolsa'
                            },
                            {
                                url: 'https://www.yuukicup.mx/comprar-copa-menstrual/soft-menstrual-cup',
                                image: 'https://www.yuukicup.mx/images/products/5/Yuuki-62%20-%20copia.jpg',
                                name: 'Copa menstrual SOFT PAQUETE ECONOMICO'
                            }
                        ],
                        [
                            {
                                url: 'https://www.yuukicup.mx/comprar-copa-menstrual/menstrual-cup-rainbow-jolly',
                                image: 'https://www.yuukicup.mx/images/products/21/Yuuki-30%20-%20copia.jpg',
                                name: 'Copa menstrual RAINBOW Jolly'
                            },
                            {
                                url: 'https://www.yuukicup.mx/comprar-copa-menstrual/rainbow-jolly-economy-menstrual-cup-&-bag',
                                image: 'https://www.yuukicup.mx/images/products/8/Yuuki-76%20-%20copia.jpg',
                                name: 'Copa menstrual Rainbow JOLLY ECONOMIC + bolsa'
                            },
                            {
                                url: 'https://www.yuukicup.mx/comprar-copa-menstrual/rainbow-jolly-economy-menstrual-cup',
                                image: 'https://www.yuukicup.mx/images/products/6/Yuuki-14%20-%20copia.jpg',
                                name: 'Copa menstrual Rainbow JOLLY PAQUETE ECONOMICO'
                            }
                        ],
                        [
                            {
                                url: 'https://www.yuukicup.mx/comprar-copa-menstrual/menstrual-cup-rainbow-line',
                                image: 'https://www.yuukicup.mx/images/products/22/Yuuki-64%20-%20copia.jpg',
                                name: 'Copa menstrual RAINBOW Line'
                            },
                            {
                                url: 'https://www.yuukicup.mx/comprar-copa-menstrual/rainbow-line-economy-menstrual-cup-&-bag',
                                image: 'https://www.yuukicup.mx/images/products/25/Yuuki-66%20-%20copia.jpg',
                                name: 'Copa menstrual Rainbow LINE ECONOMIC + bolsa'
                            },
                            {
                                url: 'https://www.yuukicup.mx/comprar-copa-menstrual/rainbow-line-economy-menstrual-cup',
                                image: 'https://www.yuukicup.mx/images/products/16/Yuuki-68%20-%20copia.jpg',
                                name: 'Copa menstrual Rainbow LINE PAQUETE ECONOMICO'
                            }
                        ],
                        [
                            {
                                url: 'https://www.yuukicup.mx/comprar-copa-menstrual/copa-menstrual-yuuki-country',
                                image: 'https://www.yuukicup.mx/images/products/31/Yuuki-77%20-%20copia.jpg',
                                name: 'Copa menstrual Country'
                            },
                            {
                                url: 'https://www.yuukicup.mx/comprar-copa-menstrual/copa-menstrual-country-economic-y-bolsa',
                                image: 'https://www.yuukicup.mx/images/products/38/Yuuki-75%20-%20copia.jpg',
                                name: 'Copa menstrual Country ECONOMIC + bolsa'
                            },
                            {
                                url: 'https://www.yuukicup.mx/comprar-copa-menstrual/rainbow-country-economy-menstrual-cup',
                                image: 'https://www.yuukicup.mx/images/products/36/IMG_2672.JPG',
                                name: 'Copa menstrual Country PAQUETE ECONOMICO'
                            }
                        ]
                    ]
                },
                {
                    title: 'Classic Pequeña',
                    options: [
                        [
                            {
                                url: 'https://www.yuukicup.mx/comprar-copa-menstrual/menstrual-cup-classic',
                                image: 'https://www.yuukicup.mx/images/products/9/Yuuki-53%20-%20copia.jpg',
                                name: 'Copa menstrual CLASSIC'
                            },
                            {
                                url: 'https://www.yuukicup.mx/comprar-copa-menstrual/economic-menstrual-cup-&-bag',
                                image: 'https://www.yuukicup.mx/images/products/7/Yuuki-44%20-%20copia.jpg',
                                name: 'Copa menstrual CLASSIC ECONOMIC + bolsa'
                            },
                            {
                                url: 'https://www.yuukicup.mx/comprar-copa-menstrual/economic-menstrual-cup',
                                image: 'https://www.yuukicup.mx/images/products/4/Yuuki-62%20-%20copia.jpg',
                                name: 'Copa menstrual CLASSIC PAQUETE ECONOMICO'
                            }
                        ],
                        [
                            {
                                url: 'https://www.yuukicup.mx/comprar-copa-menstrual/menstrual-cup-rainbow-jolly-classic',
                                image: 'https://www.yuukicup.mx/images/products/32/Yuuki-30%20-%20copia.jpg',
                                name: 'Copa menstrual RAINBOW Jolly Classic'
                            },
                            {
                                url: 'https://www.yuukicup.mx/comprar-copa-menstrual/rainbow-jolly-classic-economy-menstrual-cup-&-bag',
                                image: 'https://www.yuukicup.mx/images/products/35/Yuuki-76%20-%20copia.jpg',
                                name: 'Copa menstrual Rainbow JOLLY CLASSIC ECONOMIC + bolsa'
                            },
                            {
                                url: 'https://www.yuukicup.mx/comprar-copa-menstrual/rainbow-jolly-classic-economy-menstrual-cup',
                                image: 'https://www.yuukicup.mx/images/products/34/Yuuki-14%20-%20copia.jpg',
                                name: 'Copa menstrual Rainbow JOLLY CLASSIC PAQUETE ECONOMICO'
                            }
                        ]
                    ]
                },
                {
                    title: 'Soft Grande',
                    options: [
                        [
                            {
                                url: 'https://www.yuukicup.mx/comprar-copa-menstrual/menstrual-cup-soft',
                                image: 'https://www.yuukicup.mx/images/products/10/Yuuki-48%20-%20copia.jpg',
                                name: 'Copa menstrual SOFT'
                            },
                            {
                                url: 'https://www.yuukicup.mx/comprar-copa-menstrual/menstrual-cup-soft-&-bolsa',
                                image: 'https://www.yuukicup.mx/images/products/11/Yuuki-44%20-%20copia.jpg',
                                name: 'Copa menstrual SOFT ECONOMIC + bolsa'
                            },
                            {
                                url: 'https://www.yuukicup.mx/comprar-copa-menstrual/soft-menstrual-cup',
                                image: 'https://www.yuukicup.mx/images/products/5/Yuuki-62%20-%20copia.jpg',
                                name: 'Copa menstrual SOFT PAQUETE ECONOMICO'
                            }
                        ],
                        [
                            {
                                url: 'https://www.yuukicup.mx/comprar-copa-menstrual/menstrual-cup-rainbow-jolly',
                                image: 'https://www.yuukicup.mx/images/products/21/Yuuki-30%20-%20copia.jpg',
                                name: 'Copa menstrual RAINBOW Jolly'
                            },
                            {
                                url: 'https://www.yuukicup.mx/comprar-copa-menstrual/rainbow-jolly-economy-menstrual-cup-&-bag',
                                image: 'https://www.yuukicup.mx/images/products/8/Yuuki-76%20-%20copia.jpg',
                                name: 'Copa menstrual Rainbow JOLLY ECONOMIC + bolsa'
                            },
                            {
                                url: 'https://www.yuukicup.mx/comprar-copa-menstrual/rainbow-jolly-economy-menstrual-cup',
                                image: 'https://www.yuukicup.mx/images/products/6/Yuuki-14%20-%20copia.jpg',
                                name: 'Copa menstrual Rainbow JOLLY PAQUETE ECONOMICO'
                            }
                        ],
                        [
                            {
                                url: 'https://www.yuukicup.mx/comprar-copa-menstrual/copa-menstrual-yuuki-country',
                                image: 'https://www.yuukicup.mx/images/products/31/Yuuki-77%20-%20copia.jpg',
                                name: 'Copa menstrual Country'
                            },
                            {
                                url: 'https://www.yuukicup.mx/comprar-copa-menstrual/copa-menstrual-country-economic-y-bolsa',
                                image: 'https://www.yuukicup.mx/images/products/38/Yuuki-75%20-%20copia.jpg',
                                name: 'Copa menstrual Country ECONOMIC + bolsa'
                            },
                            {
                                url: 'https://www.yuukicup.mx/comprar-copa-menstrual/rainbow-country-economy-menstrual-cup',
                                image: 'https://www.yuukicup.mx/images/products/36/IMG_2672.JPG',
                                name: 'Copa menstrual Country PAQUETE ECONOMICO'
                            }
                        ]
                    ]
                },
                {
                    title: 'Classic Grande',
                    options: [
                        [
                            {
                                url: 'https://www.yuukicup.mx/comprar-copa-menstrual/menstrual-cup-classic',
                                image: 'https://www.yuukicup.mx/images/products/9/Yuuki-53%20-%20copia.jpg',
                                name: 'Copa menstrual CLASSIC'
                            },
                            {
                                url: 'https://www.yuukicup.mx/comprar-copa-menstrual/economic-menstrual-cup-&-bag',
                                image: 'https://www.yuukicup.mx/images/products/7/Yuuki-44%20-%20copia.jpg',
                                name: 'Copa menstrual CLASSIC ECONOMIC + bolsa'
                            },
                            {
                                url: 'https://www.yuukicup.mx/comprar-copa-menstrual/economic-menstrual-cup',
                                image: 'https://www.yuukicup.mx/images/products/4/Yuuki-62%20-%20copia.jpg',
                                name: 'Copa menstrual CLASSIC PAQUETE ECONOMICO'
                            }
                        ],
                        [
                            {
                                url: 'https://www.yuukicup.mx/comprar-copa-menstrual/menstrual-cup-rainbow-jolly-classic',
                                image: 'https://www.yuukicup.mx/images/products/32/Yuuki-30%20-%20copia.jpg',
                                name: 'Copa menstrual RAINBOW Jolly Classic'
                            },
                            {
                                url: 'https://www.yuukicup.mx/comprar-copa-menstrual/rainbow-jolly-classic-economy-menstrual-cup-&-bag',
                                image: 'https://www.yuukicup.mx/images/products/35/Yuuki-76%20-%20copia.jpg',
                                name: 'Copa menstrual Rainbow JOLLY CLASSIC ECONOMIC + bolsa'
                            },
                            {
                                url: 'https://www.yuukicup.mx/comprar-copa-menstrual/rainbow-jolly-classic-economy-menstrual-cup',
                                image: 'https://www.yuukicup.mx/images/products/34/Yuuki-14%20-%20copia.jpg',
                                name: 'Copa menstrual Rainbow JOLLY CLASSIC PAQUETE ECONOMICO'
                            }
                        ],
                    ]
                }
            ];

            let selected = null;
            let secondOption = null;

            switch (q1) {
                case 1:
                    selected = 0;
                    secondOption = 1;
                    break;
                case 3:
                case 4:
                    selected = 2;
                    secondOption = 3;
                    break;
            }

            let option1 = [0, 1];
            let option2 = [2, 3];
            switch (q6) {
                case 1:
                case 2:
                    if (option1.includes(selected) || option1.includes(secondOption)) {
                        selected = 1;
                        secondOption = 1;
                    } else if (option2.includes(selected) || option2.includes(secondOption)) {
                        selected = 3;
                        secondOption = 3;
                    } else {
                        selected = 1;
                        secondOption = 3;
                    }

                    break;
                case 3:
                case 4:
                    if (option1.includes(selected) || option1.includes(secondOption)) {
                        selected = 0;
                        secondOption = 0;
                    } else if (option2.includes(selected) || option2.includes(secondOption)) {
                        selected = 2;
                        secondOption = 2;
                    } else {
                        selected = 0;
                        secondOption = 2;
                    }
                    break;
            }

            if (selected !== secondOption) {
                switch (q7) {
                    /*case false:

                        break;*/
                    case true:
                        selected = secondOption;
                        break;
                }
            }

            if (q9) {
                if (q9.includes(1)) {
                    if (selected === 0) {
                        selected = 1;
                    } else if (selected === 2) {
                        selected = 3;
                    }
                }

                if (q9.includes(2)) {
                    if (selected === 0) {
                        selected = 2;
                    } else if (selected === 1) {
                        selected = 3;
                    }
                }
            }

            this.selectedResult = options[selected];
            this.showSurvey = false;

        });
    }
}
</script>

<style>
</style>
